<template>
  <v-card>
    <v-card-title>
      User Profile
      <v-spacer />

      <v-text-field
        class="pr-4"
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on"
            href="https://redirection.insightzclub.com/status/reward"
            target="_blank"
            color="primary"
            disabled
          >Check User Profile Sync Status</v-btn>
        </template>

        <span>
          Username: melvin.c@insightzclub.com<br>Password: test123*
        </span>
      </v-tooltip>
    </v-card-title>
    <div class="text-right my-3 mr-5">

      <v-btn @click="$router.push('/mobile/userProfiling/create')" color="success">add user profile</v-btn>
    </div>
    <div class="px-4">
      <v-autocomplete
        :items="userProfiling"
        item-text="name"
        item-value="_id"
        autocomplete
        persistent-hint
        hint="Choose User Profiling and click select"
        v-model="dataToSend.userProfilingId"
        label="Choose User Profiling"
      >
        <template slot='selection' slot-scope='{ item }'>
          {{ $languageProcessor.getEnText(item.name) }}
        </template>

        <template slot='item' slot-scope='{ item }'>
          {{ $languageProcessor.getEnText(item.name) }}
          <v-spacer></v-spacer>
          <v-btn
            class="mx-5"
            fab
            dark
            x-small
            color="cyan"
            @click="$router.push({ path: `/mobile/userProfiling/${item._id}/edit` })"
          >
          <v-icon dark>
            mdi-pencil
          </v-icon>
          </v-btn>
        </template>
      </v-autocomplete>

      <!-- Switch -->
      <v-col cols="12">
        <v-row>
          <v-switch
            v-model="filterStartDate"
            :label="`Filter by Start Date`"
            color="primary"
            class="mr-4"
          ></v-switch>
          <v-switch
            v-model="filterEndDate"
            :label="`Filter by End Date`"
            color="primary"
            class="mr-4"
          ></v-switch>
        </v-row>
      </v-col>

      <!-- Date Pickers -->
      <v-col cols="12" v-if="filterStartDate || filterEndDate">
        <v-row>
          <v-menu
            v-if="filterStartDate"
            ref="startDateMenu"
            v-model="startDateMenu"
            :close-on-content-click="false"
            :return-value.sync="startDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.startDate"
                label="Start Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              color="primary"
              no-title
              scrollable
              v-model="dataToSend.startDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.startDateMenu.save(Date(startDate))"
                >OK</v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu
            v-if="filterEndDate"
            ref="endDateMenu"
            v-model="endDateMenu"
            :close-on-content-click="false"
            :return-value.sync="endDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dataToSend.endDate"
                label="End Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>

            <v-date-picker
              no-title
              color="primary"
              scrollable
              v-model="dataToSend.endDate"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateMenu.save(Date(endDate))"
              >OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-row>
      </v-col>
    </div>
    
    <!-- Buttons -->
    <v-btn :disabled="dataToSend.userProfilingId == null"
      class="primary ml-4"
      @click="getUserProfilingSubmissions"
      >Load Submissions</v-btn
    >
 
    <!-- Data Table -->
    <v-data-table
      :headers="headers"
      :items="userProfilingData"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <!-- <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/userActivity/${item.userId}/${item.projectId}/edit` }) ">edit</v-icon>  -->
        <a :href="'/mobile/userProfiling/' + item.userId + '/' + item.userProfilingId+ '/submissions'" target="_blank">View</a>
      </template>
    </v-data-table>

  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";
const service = new RestResource();

export default {
  data() {
    return {
      search: "",
      projects: [],
      activities: [],
      userProfiling: [],
      userProfilingData: [],
      headers: [
        { text: "UserId", value: "userId", sortable: true },
        { text: "UserProfilingId", value: "userProfilingId", sortable: true },
        { text: "Count", value: "count", sortable: true },
        { text: "Action", value: "actions", sortable: false },
      ],
      startDate: null,
      endDate: null,
      startDateMenu: false,
      endDateMenu: false,
      filterStartDate: false,
      filterEndDate: false,
      dataToSend: {
        userProfilingId: null,
        startDate: undefined,
        endDate: undefined,
      }
    };
  },

  mounted() {
    this.getUserProfilings()
  },

  methods: {
    getUserProfilingSubmissions() {
      this.$setLoader()

      service.getUserProfilingData({ ...this.dataToSend, getSubmissionCount: true }).then(data => {
        for (let row of data.data) {
          row.userProfilingId = this.dataToSend.userProfilingId
        }

        this.userProfilingData = data.data

        this.$disableLoader()
      })
    },
    getUserProfilings() {
      service.getUserProfilings().then(r => {
        this.userProfiling = r.data
      })
    }
  },
};
</script>